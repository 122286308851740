import { cloneElement } from "react";

export interface ISidebarIconProps {
  icon: any;
  className?: string;
  size?: string;
  variant?: string;
}

export default function SidebarIcon({
  icon,
  className,
  ...rest
}: ISidebarIconProps) {
  return <>{cloneElement(icon, { className, ...rest })}</>;
}
