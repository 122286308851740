import { ReactElement, ReactNode, forwardRef, useMemo } from "react";
import { NotificationIcon } from "@/components";
import { useRouter } from "next/router";
import { ArrowDown2, ArrowRight2 } from "iconsax-react";
import { Box, createPolymorphicComponent } from "@mantine/core";

import SidebarIcon from "./sidebarIcon";
import Link from "next/link";
import clsx from "clsx";

export interface Item {
  text: string;
  icon: ReactNode;
  active: boolean;
  collapsed?: boolean;
  link: string;
  visible: boolean;
  children?: Array<Item>;
  pending?: number;
}

interface ItemProps {
  item: Item;
  subMenu?: boolean;
  handleCollapse(menu: string): void;
}

interface SidebarItemProps extends Omit<ItemProps, "isActive"> {
  collapsed?: boolean;
}

const _Item = forwardRef<HTMLAnchorElement, ItemProps>((props, ref) => {
  const router = useRouter();

  const { item, subMenu, handleCollapse, ...rest } = props;
  const isActive = useMemo(
    () => router.pathname.includes(item.link) && !item.children,
    [router]
  );

  function handleSidebarItemClick(menu: string) {
    if (item.children) {
      handleCollapse(menu);
    } else {
      handleCollapse("");
      router.push(item.link);
    }
  }

  return (
    <Box<"a">
      ref={ref}
      component={item.children ? undefined : "a"}
      className={clsx(
        "border-l-4 text-sm border-l-transparent",
        "flex px-4 gap-4 items-center h-10 py-6 my-2 cursor-pointer",
        isActive && "border-l-accent-90 bg-[#EDF0F5]",
        subMenu && "pl-7"
      )}
      onClick={() => handleSidebarItemClick(item.text)}
      {...rest}
    >
      <SidebarIcon
        icon={item.icon}
        className={isActive ? "text-accent-90" : ""}
        size="20"
        variant="Bulk"
      />
      <span className="flex-1 font-normal text-primary-90">{item.text}</span>
      {item.children && (
        <SidebarIcon
          icon={item.collapsed ? <ArrowDown2 /> : <ArrowRight2 />}
          variant="Outline"
          size="16"
          className="text-primary-90"
        />
      )}
      <NotificationIcon>{item?.pending}</NotificationIcon>
    </Box>
  );
});

_Item.displayName = "Item";
const Item = createPolymorphicComponent<"a", ItemProps>(_Item);

export function SidebarItem(props: SidebarItemProps): ReactElement {
  const {
    item: { children, link, collapsed },
    handleCollapse,
  } = props;

  return (
    <>
      {children ? (
        <Item {...props} />
      ) : (
        <Link href={link} passHref>
          <Item {...props} />
        </Link>
      )}

      {children && collapsed && (
        <Box>
          {children
            .filter((item) => item.visible)
            .map((item) => (
              <SidebarItem
                key={item.link}
                item={item}
                handleCollapse={handleCollapse}
                subMenu
              />
            ))}
        </Box>
      )}
    </>
  );
}
