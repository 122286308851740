import { ReactNode, useState } from "react";
import { Button, clsx, Modal, ScrollArea, Stack } from "@mantine/core";
import { ToggleOffCircle } from "iconsax-react";
import { getCookie } from "cookies-next";

import { useSidebarItems } from "./useSidebarItems";
import { useLogout } from "@/hooks/utils";
import { EnableAuthenticatorApp, LoginWithOTP, SignIn } from "../login";
import { Authenticate } from "@/sample/mutations";
import { SidebarItem } from "./sidebarItem";

const initialLogoutModalValue = {
  title: "",
  open: false,
  component: null,
};

export default function Sidebar(props) {
  const logout = useLogout();

  const [active, setActive] = useState("");

  function handleCollapse(menu: string) {
    if (active === menu) return setActive("");
    setActive(menu);
  }

  const sidebarItems = useSidebarItems(active);

  const [logoutModal, setLogoutModal] = useState<{
    open: boolean;
    title: string;
    component: ReactNode;
  }>(initialLogoutModalValue);

  const closeLogoutModal = () => setLogoutModal(initialLogoutModalValue);

  const handleEnableAuthenticator = (data: Authenticate["data"]) => {
    setLogoutModal({
      title: "Secure your Account",
      open: true,
      component: (
        <EnableAuthenticatorApp
          closeModal={closeLogoutModal}
          hash={data.secret}
          email={data.email}
          url={data.url}
        />
      ),
    });
  };

  const handleLoginWithOTP = (data: Authenticate["data"]) => {
    setLogoutModal({
      title: "Authentication Code",
      open: true,
      component: <LoginWithOTP email={data.email} />,
    });
  };

  function onSettled(userData) {
    const { data = null } = { ...userData };
    if (userData && data) {
      if (!data?.has_setup_2FA) handleEnableAuthenticator(data);
      else handleLoginWithOTP(data);
    }
  }

  const handleChangeAuthenticatorApp = () => {
    setLogoutModal({
      title: "Enter your Credentials",
      open: true,
      component: (
        <SignIn
          className="gap-4"
          onSettled={onSettled}
          url="requestLoginCode"
          headers={{
            Authorization: `Bearer ${getCookie("ecn.client.auth.token")}`,
          }}
        />
      ),
    });
  };

  const handleLogOut = () => {
    setLogoutModal({
      title: "Would you like to log out?",
      open: true,
      component: (
        <Stack>
          <Button fullWidth className="ecn-button" size="md" onClick={logout}>
            Yes, Logout
          </Button>
          <Button
            fullWidth
            className="bg-accent-90"
            size="md"
            onClick={handleChangeAuthenticatorApp}
          >
            Change Authentication App
          </Button>
        </Stack>
      ),
    });
  };

  return (
    <aside className="grid justify-between pt-4 sidebar bg-generic-background shadow-cmd">
      <ScrollArea
        styles={{
          root: {
            width: "max-content",
            minWidth: 300,
          },
        }}
        scrollHideDelay={0}
        scrollbarSize={6}
        type="hover"
      >
        <Stack spacing={0}>
          {sidebarItems
            .filter((item) => item.visible)
            .map((item, idx) => (
              <SidebarItem
                item={item}
                handleCollapse={handleCollapse}
                key={idx}
              />
            ))}
        </Stack>
      </ScrollArea>

      <div className="px-4 py-3 mt-auto border-t">
        <Button
          fullWidth
          className={clsx(
            "bg-generic-foreground shadow-csm hover:bg-generic-foreground",
            "w-full px-4 py-2 text-sm text-left text-primary-90"
          )}
          onClick={handleLogOut}
          leftIcon={<ToggleOffCircle variant="Bulk" />}
        >
          Log out
        </Button>
      </div>

      <Modal
        title={logoutModal.title}
        opened={logoutModal.open}
        onClose={closeLogoutModal}
        classNames={{
          inner: "items-center",
        }}
      >
        {logoutModal.component}
      </Modal>
    </aside>
  );
}
