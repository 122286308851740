import { Button, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { getCookie, setCookie } from "cookies-next";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";

import { Form } from "@/components";
import { clearErrorMessage, queryVars } from "@/hooks/utils";
import { AuthenticationProvider } from "@/provider";
import { LoginType } from "@/sample/queries";
import { authAxios } from "@/utils/utils";

import { SuccessNotification } from "./successNotification";
import { ErrorNotification } from "./errorNotification";

import axios from "axios";
import * as Yup from "yup";
import { encrypt } from "@/utils/lib";

async function loginFn(data) {
  return axios
    .post(queryVars.query.authenticateOtp, data, {
      baseURL: process.env.NEXT_PUBLIC_BASE_URL,
    })
    .then((res) => res.data)
    .catch((response) => {
      showNotification({
        title: "Operation failed",
        message: response.message,
        color: "accent.8",
      });
    });
}

interface IOTP {
  email: string;
  closeModal?(): void;
}

export function OTP({ email, closeModal }: IOTP) {
  const otpForm = useForm<{
    email: string;
    otp_code: string;
  }>({
    initialValues: {
      email,
      otp_code: "",
    },
    validate: yupResolver(
      Yup.object().shape({
        email: Yup.string().required("Email is required"),
        otp_code: Yup.string().length(6, "The code must be 6-digit in length"),
      })
    ),
  });

  const {
    mutate: login,
    isLoading: loginLoading,
    data: loginData,
    isSuccess: loginSuccess,
  } = useMutation<LoginType>(loginFn, {
    onSuccess(data) {
      if (data?.responseCode === "103") {
        showNotification({
          title: data.message,
          message: (
            <pre className="max-h-full overflow-auto whitespace-pre-line">
              {clearErrorMessage(data.errors)}
            </pre>
          ),
          color: "red",
        });
      }

      if (data?.responseCode === "100" && closeModal) {
        setTimeout(closeModal, 1000);
      }
    },
  });

  const handleSubmit = (value) => login(value);

  const { data = null } = { ...loginData };
  const router = useRouter();

  const [, setIsAuthenticated] = useContext(AuthenticationProvider);
  const [successNotification, setSuccessNotification] = useState<string>("");
  const [errorNotification, setErrorNotification] = useState<string>("");

  useEffect(() => {
    if (data && data?.responseCode === "100") {
      const { firstname, lastname, username, lifetime } = data;
      const userBio = JSON.stringify({
        firstname,
        lastname,
        username,
      });

      setSuccessNotification(data.message);

      // Escape if Authentication app is being changed.
      if (closeModal) return;

      setIsAuthenticated(true);
      setCookie("ecn.client.auth.token", data?.token, {
        expires: new Date(Date.now() + (lifetime ? +lifetime : 7200) * 1000),
        maxAge: lifetime ? +lifetime : 7200,
      });

      localStorage.setItem("ecn.user.bio", userBio);
      localStorage.setItem("ecn.client.authenticated", encrypt("true"));

      const permissions = JSON.stringify(data?.permissions ?? []);
      localStorage.setItem("ecn.user.permissions", encrypt(permissions));

      authAxios.defaults.headers.common["Authorization"] = `Bearer ${getCookie(
        "ecn.client.auth.token"
      )}`;

      const lastPage = sessionStorage.getItem("ecn.user.last.page");
      setTimeout(() => {
        router.push(lastPage === "/" ? "/dashboard" : lastPage ?? "/dashboard");
      }, 1000);
    } else {
      setErrorNotification(data?.message ?? "");
    }
  }, [loginSuccess]);

  return (
    <Form px={undefined} onSubmit={otpForm.onSubmit(handleSubmit)}>
      <TextInput
        id="login-otp"
        placeholder="Input the Authentication Code"
        size="md"
        name="otp"
        {...otpForm.getInputProps("otp_code")}
      />
      <Button
        fullWidth
        radius="md"
        classNames={{
          root: "bg-accent-90",
        }}
        className="font-semibold"
        type="submit"
        disabled={loginLoading}
        loading={loginLoading}
        loaderPosition="right"
        size="md"
      >
        Submit
      </Button>

      {successNotification && (
        <SuccessNotification
          close={setSuccessNotification}
          message={loginData?.message}
        />
      )}

      {errorNotification && (
        <ErrorNotification
          close={setErrorNotification}
          message={loginData?.message}
        />
      )}
    </Form>
  );
}
