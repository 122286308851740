import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import AuthLayout from "./authLayout";
import { decrypt } from "@/utils/lib";

function AuthWrapper({ children, options }) {
  const { replace, asPath } = useRouter();
  const [showChild, setShowChild] = useState(false);

  useEffect(() => {
    const value = localStorage.getItem("ecn.client.authenticated");
    const authenticated = decrypt(value ?? "") === "true";

    sessionStorage.setItem(
      "ecn.user.last.page",
      /\w?\/(\[\w+\])/.test(asPath) ? "/" : asPath
    );

    if (!authenticated) replace("/login", undefined, { shallow: true });
    else setShowChild(true);
  }, []);

  return showChild ? <AuthLayout {...options}>{children}</AuthLayout> : null;
}

function withAuth(WrappedComponent: React.FC<any>, options = {}) {
  return function AuthWrap() {
    return (
      <AuthWrapper options={options}>
        <WrappedComponent />
      </AuthWrapper>
    );
  };
}

export default withAuth;
