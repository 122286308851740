import { Stack } from "@mantine/core";
import Head from "next/head";
import Navbar from "@/layout/partials/navBar";
import Sidebar from "@/layout/sideBar";

export default function AuthLayout({
  children,
  withNavbar = true,
  withSidebar = true,
}) {
  return (
    <Stack className="h-screen max-h-screen" spacing={0}>
      <Head>
        <title>ECN</title>
        <meta name="description" content="ECN" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {withNavbar && (
        <Navbar
          logoUrl={withSidebar ? "/dashboard" : "/client"}
          isDashboard={!withSidebar}
        />
      )}
      {withSidebar ? (
        <main className="flex flex-1 overflow-y-auto">
          <Sidebar />
          <div className="p-5 overflow-auto grow">{children}</div>
        </main>
      ) : (
        <>{children}</>
      )}
    </Stack>
  );
}
