import { Notification } from "@mantine/core";
import { CloseSquare } from "iconsax-react";

export function ErrorNotification({ close, message }) {
  return (
    <Notification
      onClose={() => close("")}
      icon={<CloseSquare size="32" color="#DD5A56" />}
      classNames={{
        icon: "!bg-transparent",
        root: "w-full",
      }}
    >
      {message}
    </Notification>
  );
}
