import { Stack } from "@mantine/core";
import { useState, useEffect } from "react";

import { OTP } from "./otp";

import QRCode from "qrcode";
import Image from "next/image";

interface IEnableAuthenticatorApp {
  email: string;
  hash: string;
  url: string;
  closeModal?(): void;
}

export function EnableAuthenticatorApp({
  hash,
  email,
  url,
  closeModal,
}: IEnableAuthenticatorApp) {
  const [image, setImage] = useState("");

  useEffect(() => {
    QRCode.toDataURL(url).then((data) => {
      setImage(data);
    });
  }, []);

  return (
    <Stack>
      <ol className="grid gap-2 ml-4 list-decimal">
        <li>
          Install Google Authenticator (
          <a
            target="_blank"
            rel="noreferrer"
            href="https://apps.apple.com/us/app/google-authenticator/id388497605"
            className="font-semibold text-accent-90"
          >
            IOS
          </a>{" "}
          /{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US"
            className="font-semibold text-accent-90"
          >
            Android
          </a>
          ) or Authy (
          <a
            target="_blank"
            rel="noreferrer"
            href="https://apps.apple.com/us/app/twilio-authy/id494168017"
            className="font-semibold text-accent-90"
          >
            IOS
          </a>{" "}
          /{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://play.google.com/store/apps/details?id=com.authy.authy&hl=en&gl=US"
            className="font-semibold text-accent-90"
          >
            Android
          </a>
          )
        </li>
        <li>Scan this QR code in an authenticator app</li>

        <div className="relative grid h-[30vh]">
          {image && (
            <Image
              src={image}
              layout="fill"
              sizes="(max-width: 400px) 100vw"
              objectFit="contain"
              alt="OTP Code"
            />
          )}
        </div>

        <div className="grid gap-1 text-center">
          <p>You can also enter the code manually</p>
          <p className="font-bold text-accent-90">{hash}</p>
        </div>

        <li>Enter the 6-digit code generated by the Authenticator App</li>

        <OTP email={email} closeModal={closeModal} />
      </ol>
    </Stack>
  );
}
