import { Stack } from "@mantine/core";
import { OTP } from "./otp";

export function LoginWithOTP({ email }) {
  return (
    <Stack spacing="xl" mt="xl">
      <p className="font-semibold text-center">
        Enter the 6-digit code generated by your Authenticator app for{" "}
        <span className="font-bold text-accent-90">{email}</span>
      </p>
      <OTP email={email} />
    </Stack>
  );
}
